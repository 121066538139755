.vertical-menu {

    @extend .mb-5;

    li {
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }

    .offcanvas & {
        padding: 0;
        @extend .mt-7;
        background: none;
        border-radius: 0;
    }
}

.offcanvas[data-animation="slide-right"] {
    background-color: $color-bg-light;
    overflow-y: scroll;
}

.navbar-service {
    // position: absolute;
    // top: 0;
    // right: 0;
    // float: right;
    z-index: 100;
    // border: 4px solid red;
    @extend .d-flex;
    @extend .align-items-end;

    .nav-link {
        font-weight: 400;
        color: $white;
        text-transform: inherit;
        letter-spacing: normal;
        font-size: 1.5em;

        @include media-breakpoint-down(md) {
            font-size: 1.2em;
        }

    }

    i.fa {
        margin-right: .5em;
        color: $white;
    }

    @include media-breakpoint-down(xs) {
        top: inherit;
        bottom: 0;
    }
}

.nav-tabs-minimal {
    border-color: $color-text-light;

    .nav-item {

        a {
            padding-left: 0;
            padding-right: 0;
            margin-right: 2em;
            border-width: 1px;
            font-weight: 700;
            color: $color-text-light;
            position: relative;
            top: 1px;

            &.active {
                color: $black;
                border-color: $black;
            }

            &:hover {
                color: $black;
            }
        }
    }
}
