.btn {
    font-size: 1rem;
    padding: 10px 20px 10px !important;
    letter-spacing: 0;
    text-transform: initial;
    border-radius: 0;
    outline: none;
}

.sharethis-inline-share-buttons {
    position: sticky;
    top: 120px;
}

.btn-primary,
.btn-outline-primary {
    border: none;
    // border-radius: 0;
    letter-spacing: 0 !important;
    font-size: 1em !important;
    font-weight: 400;
    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
    @include media-breakpoint-down(xs) {
        font-size: 14px;
    }

    a {
        color: $color-light;
    }
}

.btn-outline-primary {
    border: 2px solid $color-dark;
    background: none;

    a {
        color: $color-dark;
    }

    &:hover {
        a {
            color: $color-light;
        }
    }

    .text-white & {
        border-color: $color-light;

        a {
            color: $color-light;
        }
    }
}


.gdpr-cookieconsent-settings {
    button {
        @extend .btn-primary;
    }
}