.vertical-menu {
    z-index: 9;
    position: relative;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            border-bottom: 1px solid $color-text-light;
            &.isLast {
              border-bottom: none;
            }
        }

        ul {
            display: none;
            font-size: 16px;

            li {      
                left: 1em;
                border-bottom: 0;
                position: relative;
                padding: 0;

                a {
                  line-height: 1.4em;
                  padding: 0.5em 0;

                    &:after {
                        content: "\f105";
                        opacity: 0;
                        position: absolute;
                        left: -1em;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        float: left;
                        font-family: FontAwesome;
                        color: $color-primary;
                    }
                }

                &.current>a:after {
                    line-height: 1.4em;
                    padding: 0.5em 0;
                    content: "\f105";
                    opacity: 1;
                    font-family: FontAwesome;
                    color: $color-primary;
                }
            }
        }
    }

    a {
        display: block;
        // padding: 0 2rem;
        line-height: 2.5rem;
        // white-space: wrap;
        text-decoration: none;

        >i:first-child {
            width: 2rem;
            display: inline-block;
        }
    }



    li.show>ul {
        display: block;
    }
}

.vertical-menu-parent {
    >a:before {
        content: "\F107";
        font-family: FontAwesome;
        float: right;
        color: $color-primary;
    }

    &.show>a:before {
        content: "\F106";
    }
}

.vertical-menu-light {
    background: $color-bg-light;
    border-radius: 0 0 30px 0;
    padding: 40px;
    position: sticky;
    top: 0;

    li {

        &.show,
        &.active {
            // background: rgba(33, 33, 33, 0.03);
        }

        &.show>a,
        &.current>a,
        &.active>a {
            color: $color-text-darker;
        }
    }

    a {
        color: $color-text-light;

        &:hover,
        &:focus {
            color: $color-text-darker;
            // background: rgba(33, 33, 33, 0.05);
        }
    }
}
