// @include media-breakpoint-down(xs) {

//     .jonnitto-imagesinarow__row {
//         margin: 0 -.5vw !important;
//         display: block !important;
//     }

//     .jonnitto-imagesinarow__row>.jonnitto-imagesinarow__image {
//         padding: .5vw !important;
//         display: block !important;
//         max-width: 100% !important;
//     }

//     .jonnitto-imagesinarow__row img {
//         width: 100% !important;
//         max-width: none !important;
//         height: auto !important;
//         display: block !important;
//         -o-object-fit: fill !important;
//         object-fit: fill !important;
//     }
// }

// .jonnitto-imagesinarow__row {
//     .legende {
//         border: 4px solid green;
//         clear: both !important;
//         float: left;
//     }
// }

.caca {
    border: 2px solid red;
}

$jonnitto-imagesinarow-gutter: 1vw !default;

.jonnitto-imagesinarow__row {
  $half: $jonnitto-imagesinarow-gutter / 2;

  margin: 0 -#{$half};
  display: flex;

  > .jonnitto-imagesinarow__image {
    padding: $half;
    display: block;
    max-width: 100%;
  }

  img {
    width: 100%;
    max-width: none;
    height: auto;
    display: block;
    object-fit: fill;
  }
}
