.termine-kacheln,
.footer-form {

    .container {
        padding-bottom: 150px;
        // @extend .layer-1;
        z-index: +1;
        position: relative;
    }

    padding-top: 75px;
    padding-bottom: 0px;
    background-color: theme-color('primary-3');
    overflow: hidden;

    // @extend .bg-verlauf;

    // .card {
    //     border: none;
    //     // @extend .layer-1;
    //     // @extend .shadow-lg;
    // }

    .card-body {
        background-color: #fff;
    }
}
