// @import "components/animate";
@import "components/bootstrap-vertical-menu";

@import "components/buttons";
@import "components/cards";
@import "components/footer";
@import "components/forms";
@import "components/header";
@import "components/headings";
@import "components/hr";
@import "components/imagesinarow";
@import "components/layout";
@import "components/navigations";
@import "components/news";
@import "components/pace";
@import "components/stoerer";
@import "components/tables";
@import "components/tabs";
@import "components/typography";


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
  // border: 4px solid red;
}

[data-overlay-secondary]::before {
  background: $color-secondary;
  .paartherapie & {
    background: $color-warning;
  }
}

.link-app {
  display: block;
}