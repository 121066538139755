
// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.

// Google fonts
@import url("https://use.typekit.net/txk7gwz.css");
// @import url('https://fonts.googleapis.com/css?family=Crimson+Pro:400i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital@1&display=swap');
// Google fonts
// @import url('https://fonts.googleapis.com/css?family=Crimson+Text:400i&display=swap');

//----------------------------------------
// Grid Settings
//----------------------------------------
$grid-gutter-width: 30px !default;
$grid-item-height: 290px;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1280px,
  lg: 1440px,
  xl: 1680px
) !default;


// Grid containers
//
// // Define the maximum width of `.container` for different screen sizes.

// $container-max-widths: (
//   sm: 100%,
//   md: 768px,
//   lg: 1240px,
//   xl: 1620px
// ) !default;



//----------------------------------------
// Header Dimensions
//----------------------------------------
$header-height: 100px;
$bottom-header-height: 40px;



//----------------------------------------
// Color system
//----------------------------------------
// $blue:                            #50a1ff;
// $indigo:                          #6610f2;
$purple:                           #6B378D;
// $pink:                            #e83e8c;
// $red:                             #ff4954;
// $orange:                          #ffbe00;
// $yellow:                          #ffba00;
// $green:                           #3cd458;
// $teal:                            #20c997;
// $cyan:                            #17a2b8;

// Context colors
$color-primary:                   #A3BF3D;
$color-secondary:                 #E83888;
$color-link:                      #1139BA;

$color-success:                   #617224;
$color-info:                      #B08FC6;
$color-warning:                   #F27E2B;
$color-danger:                    #E83888;
$color-light:                     #f8f9fa;
$color-dark:                      #191919;


//----------------------------------------
// Background colors
//----------------------------------------
$color-bg-lightest:               #fcfdfe;
$color-bg-lighter:                #f9fafb;
$color-bg-light:                  #F5F1F3;
$color-bg-body:                   #D9D2D6;
$color-bg-dark:                   #191919;
$color-bg-gray:                   #fafbfb;


//----------------------------------------
// Text colors
//----------------------------------------
// $color-text-darker:               #323d47;
$color-text-darker:               #691644;
$color-text-dark:                 #555555;
$color-text:                      $color-text-darker;
$color-text-light:                #B2A2AB;
$color-text-lighter:              #bfc5ca;
$color-text-lightest:             #d3d3d3;
$color-text-secondary:            #929daf;
$color-text-disable:              #a5b3c7;
$color-text-placeholder:          #c9ccce;
$color-title:                     $color-text-darker;
$color-subtitle:                  $color-text-light;


//----------------------------------------
// Fonts
//----------------------------------------
$fonts-dir:                       '../fonts/';
$font-family-base:                "pragmatica", sans-serif;
$font-family-title:               'Crimson Pro', serif;

$font-size-base:                  1.125rem;

$font-weight-base:                400;
$line-height-base:                1.5;

$h1-font-size:                    $font-size-base;
$h2-font-size:                    $font-size-base * 2.6666666667;
$h3-font-size:                    $font-size-base * 2;
$h4-font-size:                    $font-size-base * 1;
$h5-font-size:                    $font-size-base * 1.3125;
$h6-font-size:                    $font-size-base * 1.125;
$lead-font-size:                  $font-size-base * 1.5555555556;

$h1-font-weight:                  200;
$h2-font-weight:                  400;
$h3-font-weight:                  400;
$h4-font-weight:                  500;
$h5-font-weight:                  400;
$h6-font-weight:                  500;

$display1-size:                   5rem;
$display2-size:                   4rem;
$display3-size:                   3.5rem;
$display4-size:                   3rem;

$display1-weight:                 200;
$display2-weight:                 200;
$display3-weight:                 200;
$display4-weight:                 200;


//----------------------------------------
// Navbar
//----------------------------------------
$navbar-min-height:               56px;
$navbar-offset-top:               10px;


//----------------------------------------
// Other variables
//----------------------------------------

// Feel free to override other variables here. You can see the list of
// all the available variables in `/plugin/thesaas/scss/_variables.scss`
