// .navbar-brand {
//     max-width: 300px;
// }

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-color: $color-primary;
}


.header {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    &.header-sticky {
        @include media-breakpoint-down(md) {
            position: -webkit-sticky;
            position: sticky;
            z-index: +3 !important;
            top: -320px;
        }
        @include media-breakpoint-down(sm) {
            z-index: +3 !important;
            top: -200px;
        }
    }
    

    .site-logo {
        position: absolute;
        top: 0;
        left: 0;
        margin: 60px 0 0 70px;
        @include media-breakpoint-down(md) {
            margin: 30px 0 0 50px;
            // width: 75%;
        }
        @include media-breakpoint-down(sm) {
            margin: 20px 0 0 40px;
            width: 480px;
        }
        @include media-breakpoint-down(xs) {
            margin: 15px 0 0 30px;
            width: 90%;
        }

    }

    &.header-page {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .header-content {
        position: relative;
        z-index: 1 !important;
        // position: relative;
        // height: 420px;
        .stoerer {
            @include media-breakpoint-down(xs) {
                display: none;
            }
            &.stoerer-kurs {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

        }

    }

    // @include media-breakpoint-down(lg) {
    //     .header-content { height: 420px; }
    // }
    // @include media-breakpoint-down(md) {
    //     .header-content { height: 360px; }
    // }
    // @include media-breakpoint-down(sm) {
    // }    

    i, em {
        color: $color-primary;
        font-style: normal;
    }

    // #contactMap {
    //     position: relative;
    // }
    
    .site-title {
        color: $white;
        background: rgba($color-primary,.85);
        position: absolute;
        z-index: +9 !important;
        width: 320px;
        bottom: 0;
        padding: 32px 37px;
        border-radius: 0 30px 0 0;
        .paartherapie & {
            background: rgba($color-info,.85);
        }

        @include media-breakpoint-down(sm) {
            width: inherit;
        }
        @include media-breakpoint-down(xs) {
            padding: 25px 15px;
        }


        .btn[data-toggle="offcanvas"] {
            background: none;
            float: left;

            @include media-breakpoint-down(md) {
                font-size: inherit;
            }
            @include media-breakpoint-down(sm) {
                padding: 0 !important;
            }
            @include media-breakpoint-down(xs) {
                padding: 2px 4px !important;
            }

            i.menubar {
                &:before {
                    display: block;
                    margin-left: 1em;
                    margin-top: .3em;
                    width: 23px;
                    height: 16px;
                    content: "";
                    float: right;
                    background: no-repeat center url("../img/menu-8.svg");

                    @include media-breakpoint-down(xs) {
                        margin: 0;
                    }
                }
            }

            span {
                @include media-breakpoint-down(xs)  {
                    display: none;
                }
            }
        }
    }
}