/* ---------------------------------------------------
    LAYOUT
----------------------------------------------------- */
body {
    background: $color-bg-body;

    // @include media-breakpoint-down(lg) {
    //     border-top: 2px solid green;
    // }
    // @include media-breakpoint-down(md) {
    //     border-top: 2px solid blue;
    // }
    // @include media-breakpoint-down(sm) {
    //     border-top: 2px solid red;
    // } 
    // @include media-breakpoint-down(xs) {
    //     border-top: 2px solid pink;
    // } 
    min-height: initial !important;

}       

.lt-grid-container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1440px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "lt-spc-left lt-cnt-area lt-spc-right";
    @include media-breakpoint-down(md){
        grid-template-columns: 1fr 100% 1fr;
        overflow-x: hidden;
    }
}

.lt-spc-left {
    grid-area: lt-spc-left;
}

.lt-spc-right {
    grid-area: lt-spc-right;
}

.lt-cnt-area {
    display: grid;
    max-width: 1440px;
    width: 100%;
    background: $white;

    grid-template-columns: 320px 1fr;

    // @include media-breakpoint-down(md) {
    //     grid-template-columns: 1fr;
    //     overflow-x: hidden;
    // }
    
    @include media-breakpoint-down(sm) {
        grid-template-columns: 0 1fr;
        grid-template-rows: 320px 1fr 285px;
    }
    @include media-breakpoint-down(xs) {
        grid-template-rows: 280px 1fr 285px;
    }

    grid-template-rows: 420px 1fr 285px;
    grid-template-areas: "lt-header lt-header" "lt-nav lt-cnt-main" "lt-footer lt-footer";
    grid-area: lt-cnt-area;
}

.lt-header {
    grid-area: lt-header;
}

.lt-footer {
    margin-top: 3rem;
    @extend .text-white;
    height: 285px;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 285px 50px;
    grid-template-areas: "lt-footer-cnt" "lt-footer-sub";
    grid-area: lt-footer;
    @include media-breakpoint-down(sm) {
        grid-template-rows: 285px 41px;
    }
    @include media-breakpoint-down(xs) {
        grid-template-rows: 285px 82px;
    }

    .site-logo {
        @include media-breakpoint-down(sm) {
            width: 350px;
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: 2rem;
        }
    }
    .text-right {
        address {
            @include media-breakpoint-down(xs) {
                text-align: left;
            }
        }
    }

}

.lt-footer-cnt {
    padding: 3rem;
    grid-area: lt-footer-cnt;
    @include media-breakpoint-down(sm) {
        padding: 2rem;
    }
    @include media-breakpoint-down(xs) {
        padding: 1rem;
    }

}

.lt-footer-sub {
    
    grid-area: lt-footer-sub;
    .nav-link {
        padding-left: 0;
        padding-top: .7rem;
        @include media-breakpoint-down(sm) {
            padding-top: .5rem;
            font-size: 16px;
        }
    }
    .text-sm-right {
            padding-top: .6rem;
            @include media-breakpoint-down(sm) {
            padding-top: .35rem ;
            }

    }
}

.lt-nav {
    grid-area: lt-nav;
    position: relative;
    z-index: 1 !important;
    @include media-breakpoint-down(sm) {
        display: none;
    }
    nav {
        width: 320px;
    }
}

.lt-cnt-main {
    grid-area: lt-cnt-main;
    padding: 0 4em;
    @include media-breakpoint-down(md) {
        padding: 0 2em;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 1em;
    }
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    // padding: 20px;
    margin-top: 60px;
    // min-height: calc(100vh - 230px - 230px);
    transition: all 0.3s;
    @include media-breakpoint-down(md) {
        margin-top: 40px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }

}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -320px;
    }

    #sidebar.active {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
}




.out {
    @include make-row(50px);
}

.inside {
    @include make-row(20px);
}

.content-box {
    @include make-row();
}


.h-10 {
    height: 10vh !important;
}

.h-20 {
    height: 20vh !important;
}

.h-30 {
    height: 30vh !important;
}

.h-40 {
    height: 40vh !important;
}

.h-50 {
    height: 50vh !important;
}

.h-60 {
    height: 60vh !important;
}

.h-70 {
    height: 70vh !important;
}

.h-80 {
    height: 80vh !important;
}

.h-90 {
    height: 90vh !important;
}


.h-25 {
    height: 25vh !important;
}

.h-75 {
    height: 75vh !important;
}


.h-33 {
    height: 33vh !important;
}

.h-66 {
    height: 66vh !important;
}



[data-overlay-primary]::after {
    position: absolute;
    display: block;
    min-height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    mix-blend-mode: color;
    background-color: $black;
  }

  [data-overlay-primary]::before {
      z-index: 0;
      opacity: .9;
      .paartherapie & {
          opacity: .75;
      }
  }
  [data-overlay-primary] {
      z-index: 0;
  }

img {
    &.rounded-border {
        border-radius: 2rem 0;
    }
}