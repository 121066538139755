.mauticform-innerform {
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
}

.mauticform-page-wrapper {
    @extend .row;
    margin: 0 !important;
    width: 100%;

    color: $color-primary;

    .mauticform-label {
        @extend .h5;
        width: 100%;
    }

    h3 {
        @extend .mt-4;
    }

    .small {
        .mauticform-label {
            @extend .mt-2;
            font-weight: $font-weight-normal;
        }
    }

    .mauticform-has-error {
        .mauticform-input {
            border: 2px dashed $color-primary !important;
        }
    }
}

#mauticform_label_offertauftragsanfrage_datei_auswahlen {
    display: none;
}

form {}

.mauticform-row {
    float: left;
    // display: flex !important;
    // @extend .row;
    // @extend .container;
    // @include make-col(12);
}

.mauticform {
    &-text,
    &-email,
    &-tel,
    &-select,
    &-button-wrapper {
        @extend .form-group;

        input,
        textarea {
            @extend .form-control;
            border: none;
            background-color: #efefef;
    
            &::placeholder {
                color: rgba($color-primary, 0.5);
            }
            &:focus {
                border: 1px solid rgba($color-primary, 0.5);
            }
        }
    }
    &-button-wrapper {
        @extend .justify-content-end;
    
        .btn-default {
            @extend .btn-primary;
            font-weight: $font-weight-normal;
        }
    
    }
    &-select {
        select {
            background-color: #efefef;
            // @extend .custom-select;
            @extend .form-control;
        }
    }
}

.mauticform-file {}

.mauticform-errormsg {
    color: $red;
    font-size: 0.75em;
}


.mauticform-checkboxgrp {
    a {
        text-decoration: underline;
    }
    // @extend .row;
    @extend .container;
    @extend .custom-checkbox;
    
    .mauticform-checkboxgrp-row {
        padding-left: 0;
        label {
            @extend .custom-control-label;
            font-size: 1.25rem;
            @extend .mb-0;
        }
        input {
            @extend .custom-control-input;
        }
    }
}


.mauticform-post-success {
    transition: all 0.5s;

    .mauticform-message {
        @extend h4;
        color: $color-primary;
        opacity: 1;
    }

    .mauticform-innerform {
        opacity: 0;
    }

    // border: 4px solid orange;
}
