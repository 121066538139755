.table.features {
    font-size: 18px;
    &.features {
        @extend .b-0;
    }
    td, th {
        border-color: $color-text !important;
        border-width: 1px !important;
        &:nth-child(n+2) {
            background: $white;
            // @extend .text-center;
        }
        em {
            font-weight: normal;
            font-style: normal;
        }
    }
    thead {
        // font-size: 24px;
        th {
            font-weight: 700;
        }
    }
}