.card {
    display: block;        
    &.termin {
        .card-body {
            background: #efefef;
            border-radius: 30px 0;
            padding-top: 100px;
        }
        .card-img-top {
            transform: translate(50%, 50%);

        }
        .btn {
            width: auto !important;
            text-transform: initial;
            border-radius: 0 0 15px 0;
            padding-left: .5em;
            padding-right: .5em;
            background: $color-link;
        }
    }
}