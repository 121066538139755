// @font-face {
//     font-family: 'crimson';
//     src: url('#{$fonts-dir}crimsonpro-lightitalic.woff?-fvbane') format('woff'),
//          url('#{$fonts-dir}crimsonpro-lightitalic.ttf?-fvbane') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

b, strong {
    font-weight: 700;
}



// Google likes this:
@font-face {
    font-display: fallback;
}

.lead {
    // @extend .lead-2;
    // @extend .h2;
    p {
        font-family: $font-family-title;
        font-size: $lead-font-size;
        line-height: 1.2em;
        @include media-breakpoint-down(md) {
            font-size: $lead-font-size !important;
        }
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
    }
    

    .card-body & {
        display: block;
        margin-bottom: 0.5em !important;
    }
}

.small {
    @extend .small-3;
}

.list-unstyled {
    ul {
        @extend .list-unstyled;
    }
}

main {
    ul {
        padding-left: 1em;
    }
}

.whiteLinks {
    color: $white;
}

p, ul {
    margin-bottom: 1em;
}

ul {
    position: relative;
    .lt-cnt-main &,
    .list & {
        list-style-type: none;
        padding-left: 30px;
    
        li:before {
            content: '\2014';
            position: absolute;
            margin-left: -30px;
            color: $color-primary;
        }
    }
}

h3 {
    font-family: 'Crimson Pro' !important;
    font-style: italic !important;
    font-size: 1.5rem;
}

h4 {
    font-family: $font-family-base;
}



h2 {
    margin-bottom: .5em;
}

.h1 {
    @extend h1;
}
.h2 {
    @extend h2;
}
.h3 {
    @extend h3;
}
.h4 {
    @extend h4;
}
.h5 {
    @extend h5;
}
.h6 {
    @extend h6;
}

