h1 {
    font-family: $font-family-base;
    font-weight: 400;
    margin-bottom: 1em;
    @include media-breakpoint-down(md) {
      font-size: $font-size-base !important;
    }
    @include media-breakpoint-down(sm) {
      font-size: $font-size-base/1.2857142857;
    }
  }
h2 {
  font-family: $font-family-base !important;
  font-weight: 200;
  color: $color-primary;
  .paartherapie & {
    color: $color-info;
  }
  i, em {
    color: $color-secondary;
    font-style: normal;
    .paartherapie & {
      color: $color-warning;
    }
  }
}

.h1 {
    @extend h1;
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}

.h4 {
    @extend h4;
}

.h5 {
    @extend h5;
}

.h6 {
    @extend h6;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
}

h2, .h2 {
  line-height: 1.2em;
  font-family: 'pragmatica', sans-serif;
  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 1.3em;
  }
  @include media-breakpoint-down(xs) {
    font-size: 32px;
    font-weight: 300;
  }
}

// h2, .h2 {
//   letter-spacing: -.02em;
//   font-style: italic !important;
//   @include media-breakpoint-down(md) {
//     font-size: 36px;
//   }
//   @include media-breakpoint-down(xs) {
//     font-size: 24px;
//   }
// }
h3, .h3 {
  font-family: $font-family-title;
  margin-bottom: .5em;
  font-size: $lead-font-size;
  letter-spacing: 0;
  // font-weight: 300;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;
}

  
  

  .card-body & {
    font-family: $font-family-title;
    font-style: italic !important;
    letter-spacing: -.02em;
    @include media-breakpoint-down(md) {
      font-size: 36px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 24px;
    }
  }
}
h2, .h2, h3, .h3 {
  line-height: 1.2em;
}
p, ul > li, input.form-control, textarea.form-control, address {
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

// h4, .h4 {
//   font-family: 'Roboto Condensed';
//   text-transform: uppercase;
// }

.display-1,
.display-2,
.display-3,
.display-4 {
    line-height: 1.1em;
}

.section-header {
  h2 {
    // @extend .display-2;
    margin-bottom: 1em;
  }
    text-align: left;
    max-width: 100%;
    margin: 0 auto !important;
}