$dimensions: 14em;
.stoerer {
    position: absolute;
    z-index: 2;
    text-align: center;
    width: $dimensions;
    height: $dimensions;
    border-radius: 100%;
    padding: 1em;
    // border: 2px solid;
    background: $color-light;
    p {
        color: $black;
    }
    a {
        color: $white;
    }
    .fancy * {
        // font-family: $font-family-fancy;
        margin-bottom: 0;
    }
    a {
        color: $color-link;
    }
    &.stoerer-ausbildung {
        background-color: $color-primary;
        @extend .h-350;
        @extend .w-350;
        top: 100%;
        right: 4rem;
        padding: 4rem;
        
        h3, .h3 {
            color: $success;
            .paartherapie & {
                color: $purple;
            }
        }
        p {
            color: $white;
        }
        .paartherapie & {
            background: $color-info;
        }
        @include media-breakpoint-down(sm) {
            top: 15vh;
            right: -2rem;

            a {
                font-size: 18px;
                font-weight: 400;
            }
        }
        @include media-breakpoint-down(xs) {
            a {
                font-size: 16px;
            }
        }

    }
    &.stoerer-kurs {
        background-color: $white;
        @extend .h-250;
        @extend .w-250;
        top: -2rem;
        left: 55%;
        padding: 3rem;
        h3, .h3 {
            color: $color-secondary;
            font-size: 24px;
            .paartherapie & {
                color: $color-warning;
            }
        }
        p.lead a {
            font-size: 16px;
        }
    }
}